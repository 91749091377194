import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import pleshDark from "./theme/pleshDark";
import Vote from "./pages/Vote";
import React from "react";
import {Box, CssBaseline, ThemeProvider} from "@mui/material";

function App() {
    return (
        <ThemeProvider theme={pleshDark}>
            <CssBaseline/>
            <Box sx={{position:'relative', minHeight:'75vh'}}>
                <div className="App">
                    <Routes>
                        <Route path='/' element={<Navigate to={'/vote/kkVhlm8tsbvUj4YcrhHl'}/>}/>
                        <Route path='/vote/:id' element={<Vote/>}/>
                    </Routes>
                </div>
            </Box>
        </ThemeProvider>
    );
}

export default App;
