import {Button, Stack} from "@mui/material";
import pleshDark from "../../../theme/pleshDark";
import React from "react";

const LinksQuestion = ({risposte: links}) => {
    return (
        <Stack mt={3} alignItems={'center'} spacing={2} px={3}>
            {links.map((link, i) => (
                <Button variant={'contained'} href={link.url} target={'_blank'}
                        fullWidth
                        sx={{
                            minHeight: '60px',
                            fontWeight: 'bold',
                            borderRadius: '1rem',
                            background: link.color || pleshDark.palette.accent.secondary2
                        }}
                >
                    {link.label}
                </Button>
            ))}
        </Stack>
    )
}

export default LinksQuestion