const ColorQuestion = ({element}) => {
    const question = document.querySelector('#questionDialogBg')
    const pinnedQuestion = document.querySelector('#a'+element.id)
    //const pinnedQuestion = document.querySelector('#pinnedQuestionDialogBg')

    if(!!question) {
        question.style.backgroundColor = element.color;
    }

    if(!!pinnedQuestion) {
        console.log("'#a'+element.id:",'#a'+element.id)
        console.log("element.color",element.color)
        pinnedQuestion.style.backgroundColor = element.color;
    }

    return (
        <div/>
    )
}

export default ColorQuestion